

.catalog {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.composer {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: rgba(247,250,255,1) !important
}
.reportLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}



.job-results {

    overflow-y: auto;
}

.jobDetailsMenu {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.jobDetailsMenu::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

@media only screen and (max-width: 600px) {
.jobDetailsMenu .jobDetailsMenuNavLink {
    padding-top: 0 !important;
    padding-bottom: .5rem !important;
}
}

.hover-highlight:hover {
    background-color: #FFFF00;
}